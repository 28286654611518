import empFund from "../assesets/empFund.png"
import Helvetas from "../assesets/Helvetas.png"
import nepalGov from "../assesets/nepalGov.png"
import poverty from "../assesets/poverty.png"
import worldBank from "../assesets/worldBank.jpg"
import undp from "../assesets/undp.png"
import partner from "../assesets/partner.jpg"
import stromFound from "../assesets/stromFonud.jpg"
import SaMi from "../assesets/SaMi.jpg"
import CFLI from "../assesets/CFLI.jpg"
const Partners = () =>{
    return(
        <section className="organization mt-5">
            <h2 className="d-flex justify-content-center mt-5 mb-5">Our Partners</h2>
            <div className="container d-grid gap-5">
                <div className="row  align-items-center justify-content-center">
                    <div className="col-lg-4 col-sm-12 col-md-6">
                        <img src={empFund} alt="..." class="img-fluid"/>
                    </div>

                    <div className="col-lg-4 col-md-3">
                    <img src={stromFound} alt="..." class="img-fluid"/>
                    </div>

                    <div className="col-lg-4 col-md-3">
                    <img src={undp} alt="..."/>
                    </div>
                </div>

                <div className="row  align-items-center justify-content-center">
                    <div className="col-lg-4">
                        <img src={Helvetas} alt="..." class="img-fluid"/>
                    </div>

                    <div className="col-lg-4">
                    <img src={worldBank} alt="..."/>
                    </div>

                    <div className="col-lg-4">
                    <img src={poverty} alt="..."/>
                    </div>
                </div>

                <div className="row  align-items-center justify-content-center">
                    <div className="col-lg-4">
                        <img src={SaMi} alt="..."/>
                    </div>

                    <div className="col-lg-4">
                    <img src={partner} alt="..."/>
                    </div>

                    <div className="col-lg-4">
                    <img src={nepalGov} alt="..."/>
                    </div>
                </div>

                <div className="row  align-items-center justify-content-center">
                    <div className="col-lg-12">
                        <img src={CFLI} alt="..." class="img-fluid"/>
                    </div>

                   
                </div>
            </div>
        </section>
    )
}

export default Partners;