
const Contact = () =>{
    return(
        <>
            <section className="container contact px-5">
            <h2 className="d-flex justify-content-center mt-5 mb-5">Contact</h2>
                <div className="row">
                    <div className="col col-sm-12 contact-info">
                        <div className="icon"><i class="bi bi-house-door"></i></div>
                        <div className="cont-txt">
                            <p>Kalaiya Sub-Metropolitan City-6, Madarsha Tole
                                Bara, Nepal</p>
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col col-sm-12 contact-info">
                        <div className="icon"><i class="bi bi-telephone"></i></div>
                        <div className="cont-txt">
         
                            <p>+977-53-551909</p>
                            <p>9855048342</p>
                            <p>9845642482</p>
                            <p>9845225196</p>
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col col-sm-12 contact-info">
                        <div className="icon"><i class="bi bi-envelope"></i></div>
                        <div className="cont-txt">
                            <p>info@deucbara.org</p>
                            <p>deuc.bara806@gmail.com</p>
                            <p>deucbara@gmail.com</p>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact;