const Thematic = () =>{
    return(
        <>
         <section id="objectives" className="mt-5">
            <div className="container vision pb-3">
                <div className="row">
                    <div className="col">
                        <h2 className="d-flex justify-content-center mt-5 mb-5">Thematic Area</h2>
                        <ul>

                            <li className="mb-3">Education /Financial Literacy/Formal & NFE.</li>
                            <li className="mb-3">Child Protection & Gender Equality .</li>
                            <li className="mb-3">Health & Nutrition/SRHR. </li>
                            <li className="mb-3">Livelihood /Life Skill. </li>
                            <li className="mb-3">Youth & Women Empowerment. </li>
                            <li className="mb-3">Emergency /DRR/Pandemic . </li>
                            <li className="mb-3">Shock Responsive and Social Protection.</li>
                        </ul>
                    </div>
                    
                </div>

            </div>
        </section>
        </>
    )
}

export default Thematic;