import teamDetails from "../utility/team.json"
const Advisory = () =>{
    return(
        <section className="container px-5 team">
        <h2 className="d-flex justify-content-center mt-5 mb-5">Advisory Commitee</h2>
        
                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">S.N</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Area</th>
                                        </tr>
                                    </thead>
                    {
                        teamDetails?.map((data,index) => {
                            return (data.type === "advisory" ? (<>

                               
                                    <tbody>
                                        <tr>
                                              <td>{data.SN}</td>
                                            <td>{data.name}</td>
                                            <td><ul>{data.area.map(data =>{
                                               
                                                    return <li style={{listStyle:"disc"}}>{data}</li>
                                               
                                            })}</ul></td>
                                        </tr>

                                    </tbody>
                               
                            </>) : "")
                        })
                    }
                     </table>
                
                
        </section>
    )
}

export default Advisory;