const VMG = () =>{
    return(
        
        <section className="vision container mt-5">

            <div className="row">
                <div className="col-lg-12">
                    <h2 className="d-flex justify-content-center mt-5">Vision</h2>
                    <span className="border-line mt-2"></span>
                    <p className="d-flex align-items-center justify-content-center p-4" >Inclusive, Just and Prosperous society</p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12 px-5">
                    <h2 className="d-flex justify-content-center mt-4">Mission</h2>
                    <span className="border-line mt-2"></span>
                    <p className="d-flex align-items-center justify-content-center p-4" >
                        Mainstreaming the dalit, indigenous marginalized and disadvantage community in community development process through improving their socio-economic status.
                    </p>

                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <h2 className="d-flex justify-content-center mt-4">Goal</h2>
                    <span className="border-line mt-2"></span>
                    <p className="d-flex align-items-center justify-content-center p-4" >  Improved living standard of target groups in a holistic manner </p>
                </div>
            </div>



        </section>
    )
}

export default VMG;