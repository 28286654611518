import { Link } from "react-router-dom";
import FootLogo from "../../assesets/footerLogo.png"

const Footer = () => {
    return <>
        <footer className="footer mt-5">
            <div className="container-fluid p-5">
                <div className="row">
                    <div className="col-lg-3">
                        <img src={FootLogo} alt="logo" />
                    </div>

                    <div className="col-lg-3">
                        <ul className="flex-column">
                            <li class="nav-item">
                                <Link class="nav-link active" to="/" >Home</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link active" to="about-us" >About Us</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link active" to="project" >Project</Link>
                            </li>

                            <li class="nav-item">
                                <Link class="nav-link active" to="team" >Team</Link>
                            </li>

                            <li class="nav-item">
                                <Link class="nav-link active" to="contact" >Contact</Link>
                            </li>

                            <li class="nav-item">
                                <Link class="nav-link active" to="donate" >Donate</Link>
                            </li>
                        </ul>
                    </div>

                    <div className="col-lg-3">
                            <h3>Find Us</h3>
                            <div className="d-flex flex-column mt-4">
                                <p className="d-flex align-items-center" style={{gap:'1em'}}><i class="bi bi-house" style={{fontSize:"18px"}}> </i> Kalaiya Sub-Metropolitan City-6, Madarsha Tole, Bara</p>
                                <p className="d-flex align-items-center" style={{gap:'1em'}}><i class="bi bi-telephone"> </i> +977-53-551909</p>
                                <p className="d-flex align-items-center" style={{gap:'1em'}}><i class="bi bi-envelope"> </i> info@deucbara.org</p>
                                <a href="https://www.facebook.com/profile.php?id=100095441252020" target="_blank"><p className="d-flex align-items-center" style={{gap:'1em'}}><i class="bi bi-facebook"> </i></p></a>
                            </div>
                    </div>

                    <div className="col-lg-3">
                        <h3>Help and Support</h3>
                        <Link to="donate"> <button className="btn btn-lg d-grid col-7 mt-5 ms-3 justify-content-center align-items-center">Donate</button></Link>
                    </div>
                </div>
            </div>
        </footer>
    </>
}

export default Footer;