import image1 from "../assesets/Ecd.jpg"
import image2 from "../assesets/drama.jpg"
import image3 from "../assesets/childmeeting.jpg"
import image4 from "../assesets/FinancialLiteracy.jpg"
import image5 from "../assesets/policies.jpg"
import image6 from "../assesets/psy counseling.jpg"
import image7 from "../assesets/vision.jpg"
import image8 from "../assesets/vegetable_farming.jpg"
import image9 from "../assesets/LGPMC.jpg"
import image10 from "../assesets/Anual Review.jpg"
import image11 from "../assesets/Anual.jpeg"
import image12 from "../assesets/Community.jpeg"
import image13 from "../assesets/Counseling.jpg"
import image14 from "../assesets/FLC.jpg"
import image15 from "../assesets/Interaction.jpg"
import image16 from "../assesets/Jointjpg.jpg"
import image17 from "../assesets/Monitoring.jpeg"
import image18 from "../assesets/Orientationjpg.jpg"
const Images = () =>{
    return(
        <>
         <section className="gallery">
            <h2 className="d-flex justify-content-center mt-5 mb-3">Images</h2>

            <div className="container-fluid mb-5 mt-5">
                <div className="row  px-5">
                    <div className="col-lg-4 col-sm-12 gallery-img mb-sm-4">
                        <img src={image1} className="img-fluid rounded" alt="vegetable" width="100%" />
                    </div>
                    <div className="col-lg-4 col-sm-12 gallery-img mb-sm-4"><img src={image2} className="img-fluid" alt="vegetable" /></div>
                    <div className="col-lg-4 col-sm-12 gallery-img mb-sm-4"><img src={image3} className="img-fluid" alt="vegetable" /></div>
                </div>

                <div className="row px-5 mt-5">
                    <div className="col-lg-4 col-sm-12 gallery-img mb-sm-4">
                        <img src={image4} className="img-fluid rounded" alt="vegetable" width="100%" />
                    </div>
                    <div className="col-lg-4 col-sm-12 gallery-img mb-sm-4"><img src={image5} className="img-fluid" alt="vegetable" /></div>
                    <div className="col-lg-4 col-sm-12 gallery-img mb-sm-4"><img src={image6} className="img-fluid" alt="vegetable" width="100%"/></div>
                </div>

                <div className="row px-5 mt-5">
                    <div className="col-lg-4 col-sm-12 gallery-img mb-sm-4">
                        <img src={image7} className="img-fluid rounded" alt="vegetable" width="100%" />
                    </div>
                    <div className="col-lg-4 col-sm-12 gallery-img mb-sm-4"><img src={image8} className="img-fluid" alt="vegetable" /></div>
                    <div className="col-lg-4 col-sm-12 gallery-img mb-sm-4"><img src={image9} className="img-fluid" alt="vegetable" width="100%"/></div>
                </div>

                <div className="row px-5 mt-5">
                    <div className="col-lg-4 col-sm-12 gallery-img mb-sm-4">
                        <img src={image10} className="img-fluid rounded" alt="vegetable" width="100%" />
                    </div>
                    <div className="col-lg-4 col-sm-12 gallery-img mb-sm-4"><img src={image11} className="img-fluid" alt="vegetable" /></div>
                    <div className="col-lg-4 col-sm-12 gallery-img mb-sm-4"><img src={image12} className="img-fluid" alt="vegetable" width="100%"/></div>
                </div>

                <div className="row px-5 mt-5">
                    <div className="col-lg-4 col-sm-12 gallery-img mb-sm-4">
                        <img src={image13} className="img-fluid rounded" alt="vegetable" width="100%" />
                    </div>
                    <div className="col-lg-4 col-sm-12 gallery-img mb-sm-4"><img src={image14} className="img-fluid" alt="vegetable" /></div>
                    <div className="col-lg-4 col-sm-12 gallery-img mb-sm-4"><img src={image15} className="img-fluid" alt="vegetable" width="100%"/></div>
                </div>

                <div className="row px-5 mt-5">
                    <div className="col-lg-4 col-sm-12 gallery-img mb-sm-4">
                        <img src={image16} className="img-fluid rounded" alt="vegetable" width="100%" />
                    </div>
                    <div className="col-lg-4 col-sm-12 gallery-img mb-sm-4"><img src={image17} className="img-fluid" alt="vegetable" /></div>
                    <div className="col-lg-4 col-sm-12 gallery-img mb-sm-4"><img src={image18} className="img-fluid" alt="vegetable" width="100%"/></div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Images;