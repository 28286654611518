import teamDetails from "../utility/team.json"
const Executive = () =>{
    return(
        <>
         <section className="container px-5 team">
            <h2 className="d-flex justify-content-center mt-5 mb-5">Executive Board</h2>
         <div class="tab-pane fade show active" id="nav-executive" role="tabpanel" aria-labelledby="nav-executive-tab">
                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">S.N</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Gender</th>
                                            <th scope="col">Ethnicity</th>
                                            <th scope="col">Position</th>
                                        </tr>
                                    </thead>
                    {
                        teamDetails?.map((data,index) => {
                            return (data.type === "executive" ? (<>

                               
                                    <tbody>
                                        <tr>
                                              <td>{data.SN}</td>
                                            <td>{data.name}</td>
                                            <td>{data.gender}</td>
                                            <td>{data.ethnicity}</td>
                                            <td>{data.position}</td>
                                        </tr>

                                    </tbody>
                               
                            </>) : "")
                        })
                    }
                     </table>
                </div>

                <div class="tab-pane fade show active mt-4" id="nav-executive" role="tabpanel" aria-labelledby="nav-executive-tab">
                <table class="table table-hover table-bordered border-dark">
                                    <thead>
                                        <tr>
                                            <th scope="col" colspan="2">Dalit</th>
                                            <th scope="col" colspan="2">Janjati</th>
                                            <th scope="col" colspan="2">O.B.C</th>
                                            <th scope="col" colspan="2">Muslim</th>
                                            <th scope="col" colspan="3">Total</th>
                                        </tr>
                                    </thead>
                  

                               
                                    <tbody>
                                        <tr>
                                              <td>Female</td>
                                             <td>Male</td>
                                             <td>Female</td>
                                             <td>Male</td>
                                             <td>Female</td>
                                             <td>Male</td>
                                             <td>Female</td>
                                             <td>Male</td>
                                             <td>Female</td>
                                             <td>Male</td>
                                             <td>Total</td>
                                             
                                        </tr>

                                        <tr>
                                              <td>3</td>
                                             <td>1</td>
                                             <td>1</td>
                                             <td>1</td>
                                             <td>1</td>
                                             <td>1</td>
                                             <td>1</td>
                                             <td>-</td>
                                             <td>6</td>
                                             <td>3</td>
                                             <td>9</td>
                                             
                                        </tr>

                                    </tbody>
                          
                     </table>
                </div>
                </section>
        </>
    )
}

export default Executive;