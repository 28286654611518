import teamDetails from "../utility/team.json"
const Consultant = () =>{
    return(
        <section className="container px-5 team">
        <h2 className="d-flex justify-content-center mt-5 mb-5">Consultant Human Resource </h2>
     <div class="tab-pane fade show active" id="nav-executive" role="tabpanel" aria-labelledby="nav-executive-tab">
            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">S.N</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Expertise</th>
                                    </tr>
                                </thead>
                {
                    teamDetails?.map((data,index) => {
                        return (data.type === "consultant" ? (<>

                           
                                <tbody>
                                    <tr>
                                          <td>{data.SN}</td>
                                        <td>{data.name}</td>
                                        <td><ul>{data.area.map(data =>{
                                               
                                               return <li style={{listStyle:"disc"}}>{data}</li>
                                          
                                       })}</ul></td>
                                    </tr>

                                </tbody>
                           
                        </>) : "")
                    })
                }
                 </table>
            </div>
            </section>
    )
}

export default Consultant;