
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './components/Layout';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Project from './pages/Project';
import Contact from './pages/Contact';
import Donate from './pages/Donate';
import ProjectDetails from './pages/ProjectDetails';
import Team from './pages/Team';
import Objectives from './pages/Objetives';
import VMG from './pages/VMG';
import OrganizationStructure from './pages/OrganizationStructure';
import Ongoing from './pages/Ongoing';
import Completed from './pages/Completed';
import Working from './pages/Working';
import Images from './pages/Images';
import NoContent from './pages/NoContent';
import Executive from './pages/Executive';
import Advisory from './pages/Advisory';
import Human from './pages/Human';
import Partners from './pages/Partners';
import Thematic from './pages/Thematic';
import Management from './pages/Management';
import Consultant from './pages/Consultant';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="project" element={<Project />} />
        <Route path="project/:projectName" element={<ProjectDetails />} />
        <Route path="contact" element={<Contact />} />
        <Route path="donate" element={<Donate />} />
        <Route path="team" element={<Team />} />
        <Route path="objectives" element={<Objectives />} />
        <Route path="vmg" element={<VMG />} />
        <Route path="organization-structure" element={<OrganizationStructure />} />
        <Route path="ongoing" element={<Ongoing />} />
        <Route path="ongoing/:projectName" element={<ProjectDetails />} />
        <Route path="completed" element={<Completed />} />
        <Route path="completed/:projectName" element={<ProjectDetails />} />
        <Route path="working-area" element={<Working />} />
        <Route path="images" element={<Images />} />
        <Route path="no-content" element={<NoContent />} />
        <Route path="executive" element={<Executive />} />
        <Route path="advisory" element={<Advisory />} />
        <Route path="human-resource" element={<Human />} />
        <Route path="partners" element={<Partners />} />
        <Route path="thematic" element={<Thematic />} />
        <Route path="management" element={<Management />} />
        <Route path="consultant" element={<Consultant />} />
      </Route>
    </Routes>
  </BrowserRouter>
  );
}

export default App;
