const NoContent = () =>{
    return(
        <section className="noCont">
            <div className="center-div">
            <p>Coming Soon.....</p>
            </div>
        
        </section>
    )
}

export default NoContent;