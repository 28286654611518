import { Link } from "react-router-dom";
import porjectDetails from "../utility/projects.json"
const Completed = () =>{
    return (
        <>
        <section className="project container px-5">

<h2 className="d-flex justify-content-center mt-5 mb-5">Completed Projects</h2>

   
        <ul className="project-list">
           
                {
                    porjectDetails.map(data => {
                        return data.completed ?
                            (
                                <li class="py-3" style={{listStyle:"disc"}}>
                                    <Link to={data.name.toLowerCase().trim().replaceAll('/', '+')}>{data.name}</Link>
                                    
                                </li>)
                            : ''
                    })
                }
                </ul>
            
      
</section>
        </>
    )
}

export default Completed;