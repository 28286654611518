import teamDetails from "../utility/team.json"
const Team = () => {
    return (
        <section className="container px-5 team">
            <h2 className="d-flex justify-content-center mt-5 mb-5">Our Team</h2>
            <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button class="nav-link" id="nav-executive-tab" data-bs-toggle="tab" data-bs-target="#nav-executive" type="button" role="tab" aria-controls="nav-executive" aria-selected="true">Executive Board</button>
                    <button class="nav-link" id="nav-advisory-tab" data-bs-toggle="tab" data-bs-target="#nav-advisory" type="button" role="tab" aria-controls="nav-advisory" aria-selected="false">Advisory Commitee</button>
                    <button class="nav-link" id="nav-staff-tab" data-bs-toggle="tab" data-bs-target="#nav-staff" type="button" role="tab" aria-controls="nav-staff" aria-selected="false">Human Resource/Staff</button>
                    {/* <button class="nav-link" id="nav-staff-tab" data-bs-toggle="tab" data-bs-target="#nav-staff" type="button" role="tab" aria-controls="nav-staff" aria-selected="false">Staff</button> */}
                </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-executive" role="tabpanel" aria-labelledby="nav-executive-tab">
                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">S.N</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Position</th>
                                        </tr>
                                    </thead>
                    {
                        teamDetails?.map((data,index) => {
                            return (data.type === "executive" ? (<>

                               
                                    <tbody>
                                        <tr>
                                              <td>{data.SN}</td>
                                            <td>{data.name}</td>
                                            <td>{data.position}</td>
                                        </tr>

                                    </tbody>
                               
                            </>) : "")
                        })
                    }
                     </table>
                </div>
                <div class="tab-pane fade" id="nav-staff" role="tabpanel" aria-labelledby="nav-staff-tab">
                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">S.N</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Position</th>
                                        </tr>
                                    </thead>
                    {
                        teamDetails?.map((data,index) => {
                            return (data.type === "human" ? (<>

                               
                                    <tbody>
                                        <tr>
                                              <td>{data.SN}</td>
                                            <td>{data.name}</td>
                                            <td>{data.position}</td>
                                        </tr>

                                    </tbody>
                               
                            </>) : "")
                        })
                    }
                     </table>
                </div>

                <div class="tab-pane fade" id="nav-advisory" role="tabpanel" aria-labelledby="nav-advisory-tab">
                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">S.N</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Area</th>
                                        </tr>
                                    </thead>
                    {
                        teamDetails?.map((data,index) => {
                            return (data.type === "advisory" ? (<>

                               
                                    <tbody>
                                        <tr>
                                              <td>{data.SN}</td>
                                            <td>{data.name}</td>
                                            <td><ul>{data.area.map(data =>{
                                               
                                                    return <li style={{listStyle:"disc"}}>{data}</li>
                                               
                                            })}</ul></td>
                                        </tr>

                                    </tbody>
                               
                            </>) : "")
                        })
                    }
                     </table>
                </div>
            </div>
        </section>
    )
}

export default Team;