const Donate = ()=>{
    return (
        <section className="donate contaier px-5">
                <h2 className="d-flex justify-content-center mt-5 mb-5">Donate</h2>
                <div className="bank-details">


                    <div className="row mt-5">
                        <div className="col col-sm-12 contact-info">
                            <div className="icon"><i class="bi bi-building"></i></div>
                            <div className="cont-txt">
                                <h3>Bank Name:</h3>
                                <p>SANIMA Bank</p>
                                <h3>Account Number:</h3>
                                <p>032010010000117</p>
                               
                            </div>
                        </div>

                        
                    </div>
                </div>
        </section>
    )
}

export default Donate;