import { useParams } from "react-router-dom";
import porjectDetail from "../utility/projects.json"
import { useEffect, useState } from "react";
const ProjectDetails = () =>{
    const [currentProject, setProject] = useState([]);
    const params = useParams();
  
    useEffect(() => {
      const res = porjectDetail?.find((data) => {
        if (data?.name.toLowerCase() === params.projectName.replaceAll("+", "/")) {
            setProject(data);
        }
        
      });
      return res;
    }, [currentProject, params.projectName]);
    return (
        <>
        
            {currentProject ? (
                <section className="project-details container px-5">
                        <h2 className="d-flex justify-content-center mt-5 mb-5">Project Detail</h2>
                        <div className="px-5">
                            <p><strong>Project Name:</strong> {currentProject.name}</p>
                            <p><strong>Funding Agency: </strong>{currentProject.donor}</p>
                            <p><strong>Sector: </strong>{currentProject.sector}</p>
                            <p>
                                <strong>Major Achivements: </strong>
                                <ul>
                                    {currentProject.achivements?.map((data,index) => {return(<li style={{listStyle:"disc"}} className="mt-3">{data}</li>) }) }
                                </ul>
                            
                            </p>
                        </div>
                        
                </section>
           
            
            ):( "")}
          
        </>
    )
}

export default ProjectDetails;