const Objectives = () =>{
    return(
        <>
         <section id="objectives" className="mt-5">
            <div className="container vision pb-3">
                <div className="row">
                    <div className="col">
                        <h2 className="d-flex justify-content-center mt-5 mb-5">Our Objectives</h2>
                        <ul>

                            <li className="mb-3">To enhance the capacity of the CBOs/NGOs in local level to provide the quality services to the disadvantaged groups of the society.</li>
                            <li className="mb-3">To train local youths and mobilize them for the gearing up of the backward groups of the society.</li>
                            <li className="mb-3">To promote advocacy for ensuring the rights of Children, Youth, Women, Dalits, Ethnic People and Disadvantaged Groups of the Society and Human Rights. </li>
                            <li className="mb-3">To implement educational program, health and nutrition program, water and sanitation, income generating, environment promotion, private and public land plantation, alternative energy, good governance promotion, awareness and sensitization of community as well as social mobilization to bring the positive changes in the lives of targeted people in the communities. </li>
                            <li className="mb-3">To advocate for ensuring of senior citizen rights and services. </li>
                            <li className="mb-3">To construct small infrastructure development activities for poverty alleviation of community. </li>
                            <li className="mb-3">To develop linkage and coordination with local, national and international GOs, NGOs and INGOs for the fulfillment of the needs of the targeted people through partnering program</li>
                        </ul>
                    </div>

                </div>

            </div>
        </section>
        </>
    )
}

export default Objectives;