import { Link } from "react-router-dom";
import porjectDetails from "../utility/projects.json"
const Project = () => {
    return (

        <section className="project container px-5">

            <h2 className="d-flex justify-content-center mt-5 mb-5">Projects</h2>
            <div class="accordion" id="accordionPanelsStayOpenExample">
                <div class="accordion-item mb-4">
                    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                            On going Project
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">

                        {
                            porjectDetails.map(data => {
                                return (data.completed ? ("")
                                    :
                                    (
                                        <div class="accordion-body">
                                            <Link to={data.name.toLowerCase().trim().replaceAll('/', '+')}>{data.name}</Link>
                                        </div>))
                            })
                        }

                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                            Completed Project
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                       
                            {
                                porjectDetails.map(data => {
                                    return data.completed ?
                                        (
                                            <div class="accordion-body">
                                                <Link to={data.name.toLowerCase().trim().replaceAll('/', '+')}>{data.name}</Link>
                                            </div>)
                                        : ''
                                })
                            }
                        
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Project;