import map from "../assesets/map.jpg"
const Working = () =>{
    return(
        <>
              <section className="organization mt-5">
            <h2 className="d-flex justify-content-center mt-5 mb-5">Working Area</h2>
            <div className="container-fluid mt-5 mb-5 justify-content-center d-flex">
                <img src={map} className="img-fluid" alt="map" />
            </div>
        </section>
        </>
    )
}
export default Working;