import {Link} from "react-router-dom"
import Logo from "../../assesets/NavLogo.png"
import { useEffect } from "react";
const Navbar = ()=>{
 
    return (
    <header className="header px-5 px--md-1">
    <nav class="navbar navbar-expand-lg navbar-light px--sm-3 py-3 ">
    <div class="container-fluid">
      <Link class="navbar-brand d-flex justify-content-center align-items-center align-content-center" to="/">
        <img src={Logo} alt="logo"/>
        <p className="mx-3 mt-3" style={{color:"#fff",fontSize:"1.1em",fontWeight:"700"}}>दलित जनजाती उत्थान केन्द्र बारा <br /><span style={{fontSize:"0.8em"}}>Inclusive, Just and Prosperous Society</span></p>
      </Link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
       
        <div class="d-flex">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <Link class="nav-link active" to="/" >Home</Link>
          </li>
          <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            About Us
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link class="dropdown-item" to="objectives">Objectives</Link></li>
            <li><Link class="dropdown-item" to="vmg">Vision Mission & Goal</Link></li>
            <li><Link class="dropdown-item" to="organization-structure">Organization Structure</Link></li>
            <li><Link class="dropdown-item" to="thematic">Thematic Area</Link></li>
            <li><Link class="dropdown-item" to="working-area">Working Area</Link></li>
           
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Project
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link class="dropdown-item" to="ongoing">Ongoing</Link></li>
            <li><Link class="dropdown-item" to="completed">Completed</Link></li>
           
            <li><hr class="dropdown-divider"/></li>
            
          </ul>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Team
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link class="dropdown-item" to="executive">Executive</Link></li>
            <li><Link class="dropdown-item" to="advisory">Advisory</Link></li>
            <li><Link class="dropdown-item" to="human-resource">Staff</Link></li>
            <li><Link class="dropdown-item" to="management">Management Commitee</Link></li>
            <li><Link class="dropdown-item" to="consultant">Consultant Human Resource</Link></li>
            <li><hr class="dropdown-divider"/></li>
            
          </ul>
        </li>

          <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Gallery
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link class="dropdown-item" to="images">Images</Link></li>
            <li><Link class="dropdown-item" to="no-content">Video</Link></li>
           
            <li><hr class="dropdown-divider"/></li>
            
          </ul>
        </li>
        <li class="nav-item">
          <Link class="nav-link active" to="partners" >Our Partners</Link>
          </li>
          <li class="nav-item">
          <Link class="nav-link active" to="contact" >Contact</Link>
          </li>

          <li class="nav-item">
          <Link class="nav-link active" to="donate" >Donate</Link>
          </li>

          <li class="nav-item">
          <div id="google_translate_element"></div>


          </li>
        </ul>
        </div>
      </div>
    </div>
  </nav>
  </header>
  );
}

export default Navbar;