import teamDetails from "../utility/team.json"
const Human = () =>{
    return(
        <section className="container px-5 team">
        <h2 className="d-flex justify-content-center mt-5 mb-5">Human Resource/Staff</h2>
        <div class="tab-pane fade show active" id="nav-executive" role="tabpanel" aria-labelledby="nav-executive-tab">
                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">S.N</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Position</th>
                                        </tr>
                                    </thead>
                    {
                        teamDetails?.map((data,index) => {
                            return (data.type === "human" ? (<>

                               
                                    <tbody>
                                        <tr>
                                              <td>{data.SN}</td>
                                            <td>{data.name}</td>
                                            <td>{data.position}</td>
                                        </tr>

                                    </tbody>
                               
                            </>) : "")
                        })
                    }
                     </table>
                </div>
        </section>
    )
}

export default Human;