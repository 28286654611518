const Management = () =>{
    return(
        <section className="container px-5 team">
        <h2 className="d-flex justify-content-center mt-5 mb-5">Management Commitee</h2>
     <div class="tab-pane fade show active" id="nav-executive" role="tabpanel" aria-labelledby="nav-executive-tab">
            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">S.N</th>
                                        <th scope="col">Member</th>
                                        <th scope="col">Role</th>
                                    </tr>
                                </thead>
                
                           
                                <tbody>
                                    <tr>
                                    <td>1</td>
                                          <td>Executive Director</td>
                                        <td>Coordinator</td>
                                       
                                    </tr>

                                    <tr>
                                    <td>2</td>
                                          <td>Program/Project Coordinators</td>
                                        <td> Members</td>
                                       
                                    </tr>

                                    <tr>
                                    <td>3</td>
                                          <td>Executive Board Member-1per</td>
                                        <td> Member</td>
                                       
                                    </tr>

                                    <tr>
                                    <td>4</td>
                                          <td>Admin and Finance</td>
                                        <td> Member</td>
                                       
                                    </tr>

                                </tbody>
                           
                       
                 </table>
            </div>
            </section>
    )
}

export default Management;