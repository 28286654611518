import OrganizationChart from "../assesets/orgdig.png"

const OrganizationStructure = () =>{
    return(
        <>
          <section className="organization mt-5">
            <h2 className="d-flex justify-content-center mt-5 mb-5">Organization Chart</h2>
            <div className="container-fluid mt-5 mb-5 justify-content-center d-flex">
                <img src={OrganizationChart} className="img-fluid" alt="organization-chart" />
            </div>
        </section>
        </>
    )
}

export default OrganizationStructure;